import { SvgIcon, SvgIconProps } from "@mui/material";

const DeleteDialogIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 80, height: 80, fill: "none" }} viewBox="0 0 80 80" {...props}>
      <path d="M42.2192 26.6642H37.3999V39.9654H42.2192V26.6642Z" fill="#C7DBE5" />
      <path
        d="M62.0747 35.9172H17.4482V53.7486H37.4964V57.604V63.4835H42.3157V57.604V53.7486H62.1711V35.9172H62.0747ZM57.2555 48.9293H22.2675V40.7365H57.2555V48.9293Z"
        fill="#C7DBE5"
      />
      <path
        d="M53.3359 46.0072C53.9421 44.8826 53.5219 43.4796 52.3974 42.8734C51.2729 42.2672 49.8699 42.6874 49.2637 43.8119C48.6575 44.9364 49.0776 46.3395 50.2021 46.9457C51.3267 47.5519 52.7297 47.1317 53.3359 46.0072Z"
        fill="#C7DBE5"
      />
      <path
        d="M62.0747 31.4834H17.4482V13.6521H62.0747V31.4834ZM22.2675 26.6641H57.2555V18.4714H22.2675V26.6641Z"
        fill="#C7DBE5"
      />
      <path
        d="M53.3471 23.7366C53.9534 22.6121 53.5332 21.2091 52.4087 20.6029C51.2841 19.9967 49.8811 20.4168 49.2749 21.5414C48.6687 22.6659 49.0889 24.0689 50.2134 24.6752C51.3379 25.2814 52.7409 24.8612 53.3471 23.7366Z"
        fill="#C7DBE5"
      />
      <path d="M68.1469 60.592H11.4722V65.4113H68.1469V60.592Z" fill="#C7DBE5" />
      <path d="M71.5361 52.0001L54 69.5361L57.5355 73.0716L75.0716 55.5356L71.5361 52.0001Z" fill="#F44336" />
      <path d="M57.5355 52L54 55.5355L71.5361 73.0716L75.0716 69.5361L57.5355 52Z" fill="#F44336" />
    </SvgIcon>
  );
};

export default DeleteDialogIcon;
