import { createSvgIcon } from "@mui/material";

const NoAccessLockIcon = createSvgIcon(
  <svg viewBox="0 0 80 80" fill="none">
    <rect fill="white" />
    <path
      d="M55.24 53.9673C55.24 53.9673 48.7411 56.2932 40.532 56.2932C32.3229 56.2932 25.824 53.9673 25.824 53.9673V36.9333C25.824 36.9333 32.3229 34.6074 40.532 34.6074C48.7411 34.6074 55.24 36.9333 55.24 36.9333V53.9673Z"
      stroke="#C7DBE5"
      strokeWidth="5"
      strokeMiterlimit="10"
    />
    <path
      d="M31.5701 34.2657V29.819C31.5701 24.8936 35.6062 20.8574 40.5317 20.8574C45.4572 20.8574 49.4933 24.8936 49.4933 29.819V34.2657"
      stroke="#C7DBE5"
      strokeWidth="5"
      strokeMiterlimit="10"
    />
    <path
      d="M44.467 43.5136C44.467 45.7368 42.6885 47.5154 40.4652 47.5154C38.242 47.5154 36.4634 45.7368 36.4634 43.5136C36.4634 41.2903 38.242 39.5117 40.4652 39.5117C42.6885 39.5117 44.467 41.2903 44.467 43.5136Z"
      fill="#00A866"
    />
    <path d="M40.5284 44.8516V50.3105" stroke="#00A866" strokeWidth="5" strokeMiterlimit="10" />
    <circle cx="40" cy="40" r="31.2281" stroke="#C7DBE5" strokeWidth="5" strokeMiterlimit="10" />
  </svg>,
  "NoAccessLockIcon"
);

export default NoAccessLockIcon;
