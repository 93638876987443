import { Typography } from "@mui/material";
import { GridColDef, GridGroupingColDefOverride } from "@mui/x-data-grid-premium";
import { PortfolioInputFormRow } from "./portfolioInputFormTypes";

export const groupingColumnDefinition: GridGroupingColDefOverride<PortfolioInputFormRow> = {
  leafField: "metricName",
  flex: 1,
  cellClassName: "readonly-cell",
};

export const columnDefinitions: GridColDef<PortfolioInputFormRow>[] = [
  {
    field: "primaryCategory",
    headerName: "",
    renderCell: ({ rowNode, field, value }) => {
      if (rowNode.type === "group" && field === rowNode.groupingField) {
        return "";
      }

      return <Typography variant="subtitle2">{value}</Typography>;
    },
  },
  {
    field: "secondaryCategory",
    headerName: "",
    renderCell: ({ rowNode, field, value }) => {
      if (rowNode.type === "group" && field === rowNode.groupingField) {
        return "";
      }

      return <Typography variant="subtitle2">{value}</Typography>;
    },
  },
  {
    field: "metricName",
    headerName: "Metric",
    width: 300,
    renderCell: ({ row }) => <Typography pl={8}>{row.metricName}</Typography>,
  },
  {
    field: "value1",
    headerName: "July 2024",
    type: "number",
    editable: true,
    width: 200,
  },
  {
    field: "value2",
    headerName: "August 2024",
    type: "number",
    editable: true,
    width: 200,
  },
  {
    field: "value3",
    headerName: "September 2024",
    type: "number",
    editable: true,
    width: 200,
  },
];
