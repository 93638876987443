import { grey } from "@mui/material/colors";
import { useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";
import DataGrid from "../../../shared/components/grid/DataGrid";
import { columnDefinitions, groupingColumnDefinition } from "./portfolioInputFormGridDataProvider";
import { PortfolioInputFormRow } from "./portfolioInputFormTypes";

interface Props {
  rows: PortfolioInputFormRow[];
}

const PortfolioInputFormGrid = ({ rows }: Props) => {
  const gridApiRef = useGridApiRef();

  const gridInitialState = useKeepGroupedColumnsHidden({
    apiRef: gridApiRef,
    initialState: {
      rowGrouping: {
        model: ["primaryCategory", "secondaryCategory"],
      },
      columns: {
        columnVisibilityModel: {
          metricName: false,
        },
      },
    },
  });

  return (
    <DataGrid<PortfolioInputFormRow>
      apiRef={gridApiRef}
      rows={rows}
      getRowId={(row) => `${row.primaryCategory}_${row.secondaryCategory}_${row.metricName}`}
      columns={columnDefinitions}
      groupingColDef={groupingColumnDefinition}
      rowHeight={40}
      columnHeaderHeight={36}
      initialState={gridInitialState}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      disableColumnSorting
      showCellVerticalBorder
      showColumnVerticalBorder
      editMode="cell"
      isGroupExpandedByDefault={() => true}
      sx={(t) => ({
        borderColor: t.palette.divider,
        ".MuiDataGrid-columnHeaders": {
          borderTop: `1px solid ${t.palette.divider}`,
          ".MuiDataGrid-columnHeader": {
            ":first-of-type": {
              borderLeft: `1px solid ${t.palette.divider}`,
            },
          },
        },
        ".MuiDataGrid-row": {
          ".MuiDataGrid-cell": {
            ":first-of-type": {
              borderLeft: `1px solid ${t.palette.divider}`,
            },
          },
          "& .readonly-cell": {
            backgroundColor: grey[50],
          },
        },
        ".MuiDataGrid-columnHeader": {
          backgroundColor: grey[50],
        },
      })}
    />
  );
};

export default PortfolioInputFormGrid;
