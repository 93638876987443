import { PortfolioInputFormRow } from "./portfolioInputFormTypes";

export const getPortfolioInputFormRows = (): PortfolioInputFormRow[] => [
  {
    primaryCategory: "Assets",
    secondaryCategory: "Current Assets",
    metricName: "Cash and Cash Equivalents",
    value1: 48_000,
    value2: 47_000,
    value3: 42_000,
  },
  {
    primaryCategory: "Assets",
    secondaryCategory: "Current Assets",
    metricName: "Accounts Receivable",
    value1: 75_000,
    value2: 81_000,
    value3: 89_000,
  },
  {
    primaryCategory: "Assets",
    secondaryCategory: "Current Assets",
    metricName: "Inventory",
    value1: 45_000,
    value2: 69_000,
    value3: 71_000,
  },
  {
    primaryCategory: "Assets",
    secondaryCategory: "Current Assets",
    metricName: "Other Current Assets",
    value1: 0,
    value2: 0,
    value3: 5_000,
  },
  {
    primaryCategory: "Assets",
    secondaryCategory: "Non-Current Assets",
    metricName: "Property, Plant and Equipment",
    value1: 221_000,
    value2: 229_000,
    value3: 241_000,
  },
  {
    primaryCategory: "Assets",
    secondaryCategory: "Non-Current Assets",
    metricName: "Goodwill",
    value1: 0,
    value2: 0,
    value3: 0,
  },
  {
    primaryCategory: "Assets",
    secondaryCategory: "Non-Current Assets",
    metricName: "Intangible Assets",
    value1: 107_000,
    value2: 106_000,
    value3: 109_000,
  },
  {
    primaryCategory: "Assets",
    secondaryCategory: "Non-Current Assets",
    metricName: "Financial Assets",
    value1: 120_000,
    value2: 115_000,
    value3: 119_000,
  },
  {
    primaryCategory: "Assets",
    secondaryCategory: "Non-Current Assets",
    metricName: "Other Non-Current Assets",
    value1: 0,
    value2: 0,
    value3: 0,
  },
  {
    primaryCategory: "Liabilities",
    secondaryCategory: "Current Liabilities",
    metricName: "Accounts Payable",
    value1: 159_000,
    value2: 172_000,
    value3: 194_000,
  },
  {
    primaryCategory: "Liabilities",
    secondaryCategory: "Current Liabilities",
    metricName: "Short Term Debt",
    value1: 181_000,
    value2: 196_000,
    value3: 215_000,
  },
  {
    primaryCategory: "Liabilities",
    secondaryCategory: "Current Liabilities",
    metricName: "Other Current Liabilities",
    value1: 0,
    value2: 0,
    value3: 0,
  },
  {
    primaryCategory: "Liabilities",
    secondaryCategory: "Non-Current Liabilities",
    metricName: "Long Term Debt",
    value1: 311_000,
    value2: 311_000,
    value3: 476_000,
  },
  {
    primaryCategory: "Liabilities",
    secondaryCategory: "Non-Current Liabilities",
    metricName: "Minority Interest",
    value1: 91_000,
    value2: 51_000,
    value3: 105_000,
  },
  {
    primaryCategory: "Liabilities",
    secondaryCategory: "Non-Current Liabilities",
    metricName: "Other Non-Current Liabilities",
    value1: 19_000,
    value2: 19_000,
    value3: 19_000,
  },
];
