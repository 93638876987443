import { createSvgIcon } from "@mui/material";

const CasesIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor">
    <path d="M2.50016 18.3335C2.04183 18.3335 1.64947 18.1703 1.32308 17.8439C0.996691 17.5175 0.833496 17.1252 0.833496 16.6668V8.3335C0.833496 8.09738 0.913357 7.89947 1.07308 7.73975C1.2328 7.58002 1.43072 7.50016 1.66683 7.50016C1.90294 7.50016 2.10086 7.58002 2.26058 7.73975C2.4203 7.89947 2.50016 8.09738 2.50016 8.3335V16.6668H15.8335C16.0696 16.6668 16.2675 16.7467 16.4272 16.9064C16.587 17.0661 16.6668 17.2641 16.6668 17.5002C16.6668 17.7363 16.587 17.9342 16.4272 18.0939C16.2675 18.2536 16.0696 18.3335 15.8335 18.3335H2.50016ZM5.8335 15.0002C5.37516 15.0002 4.9828 14.837 4.65641 14.5106C4.33002 14.1842 4.16683 13.7918 4.16683 13.3335V5.00016C4.16683 4.76405 4.24669 4.56613 4.40641 4.40641C4.56613 4.24669 4.76405 4.16683 5.00016 4.16683H8.3335V2.50016C8.3335 2.04183 8.49669 1.64947 8.82308 1.32308C9.14947 0.996691 9.54183 0.833496 10.0002 0.833496H13.3335C13.7918 0.833496 14.1842 0.996691 14.5106 1.32308C14.837 1.64947 15.0002 2.04183 15.0002 2.50016V4.16683H18.3335C18.5696 4.16683 18.7675 4.24669 18.9272 4.40641C19.087 4.56613 19.1668 4.76405 19.1668 5.00016V13.3335C19.1668 13.7918 19.0036 14.1842 18.6772 14.5106C18.3509 14.837 17.9585 15.0002 17.5002 15.0002H5.8335ZM5.8335 13.3335H17.5002V5.8335H5.8335V13.3335ZM10.0002 4.16683H13.3335V2.50016H10.0002V4.16683Z" />
  </svg>,
  "CasesIcon"
);

export default CasesIcon;
