import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { getPortfolioInputFormRows } from "./portfolioInputFormData";
import PortfolioInputFormGrid from "./PortfolioInputFormGrid";

const PortfolioInputFormPage = () => {
  const rows = useMemo(() => getPortfolioInputFormRows(), []);

  return (
    <Stack spacing={3} flex={1} py={2.5} px={2}>
      <Typography variant="h5">Portfolio Input Form</Typography>
      <Stack flex={1} maxWidth={1024}>
        <PortfolioInputFormGrid rows={rows} />
      </Stack>
    </Stack>
  );
};

export default PortfolioInputFormPage;
